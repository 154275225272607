<template>
  <div class="card">
    <header class="card-header">
      <div class="columns is-multiline card-header-title ">
        <div class="column is-12">
          <p class="modal-card-title">
            PRODUCTOS RELACIONADOS
          </p>
        </div>
      </div>
    </header>
    <div class="card-content">
      <div class="columns is-multiline mb-2" v-if="modeEdit">
        <div class="column is-12">
          <p class="paragraph has-text-weight-bold" style="margin-bottom: 0">Agregar productos</p>
        </div>
        <div class="column is-6">
          <a-select show-search option-filter-prop="children" placeholder="Selecciona un producto" :filter-option="filterOption" v-model="productTemporalId" :style="{ width: '100%' }">
            <a-select-option v-for="products in guidesProductOptions" :key="products.id" :value="products.id">
              {{ products.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="column is-3">
          <button class="button button_micro_dark_outlined is-fullwidth" @click="addProductSelected">
            AGREGAR
          </button>
        </div>
      </div>
      <div :class="[ relatedProductsLocal.length === empty ? 'block' : 'content' ]">
        <template v-if="relatedProductsLocal.length === empty">
          <p class="paragraph has-text-centered">No hay productos relacionados</p>
        </template>
        <template v-else>
          <template v-for="(relatedProduct, indexProduct) in relatedProductsLocal">
            <div
              v-if="relatedProduct.active"
              class="media is-align-items-center mr-5"
              :class="{ 'media_border_bottom' : modeEdit }"
              :key="indexProduct"
              style="margin-top: 0; margin-bottom: 0;"
            >
              <div class="media-left">
                <figure class="image is-64x64">
                  <img :src="`${ASSETS_URL}${relatedProduct.main_image}`" :alt="relatedProduct.name" v-if="relatedProduct.main_image && relatedProduct.main_image != 'NULL'">
                  <img src="@/assets/images/default-microanalisis.png" :alt="relatedProduct.name" v-else>
                </figure>
              </div>
              <div class="media-content">
                <p class="text_18 has-text-weight-bold text_green_dark" style="margin-bottom: 0;">{{ relatedProduct.name}} </p>
                <small v-if="showCatalog">{{ relatedProduct.catalogue }}</small>
              </div>
              <div class="media-right" v-if="modeEdit">
                <img class="icon_delete" src="@/assets/icon/trash_icon.png" alt="Borrar" @click="deleteProduct(indexProduct)">
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CardRelatedProducts',
  inject: ['$validator'],
  data () {
    return {
      empty: 0,
      ASSETS_URL: process.env.VUE_APP_URL_FILES,
      relatedProductsLocal: [],
      relatedProductsSelect: [],
      productTemporalId: null
    }
  },
  props: {
    guidesProductOptions: {
      type: Array,
      default: () => []
    },
    relatedProducts: {
      type: Array,
      default: () => []
    },
    modeEdit: {
      type: Boolean,
      default: false
    },
    showCatalog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // Agregar producto relacionado
    addProductSelected () {
      if (this.productTemporalId) {
        const productDataSelect = this.guidesProductOptions.find(product => product.id === this.productTemporalId)
        // Validamos que no exista el objeto
        const productExist = this.relatedProductsLocal.findIndex(product => product.id === productDataSelect.id)
        const doesNotExist = -1
        if (productExist === doesNotExist) {
          productDataSelect.active = true
          this.relatedProductsLocal.unshift(productDataSelect)
        }
        this.productTemporalId = null
      }
    },
    deleteProduct (indexProduct) {
      const { relationId } = this.relatedProductsLocal.find((_, indexFind) => indexFind === indexProduct)
      if (relationId) {
        this.relatedProductsLocal[indexProduct].active = false
      } else {
        this.relatedProductsLocal.splice(indexProduct, 1)
      }
    },
    getDataProducts () {
      return this.relatedProductsLocal
    }
  },
  mounted () {
    this.relatedProductsLocal = _.cloneDeep(this.relatedProducts)
  },
  watch: {
    relatedProducts (newData) {
      if (newData.length) {
        this.relatedProductsLocal = _.cloneDeep(newData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
  .card-header {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    border-bottom: solid 1px #e8e8e8;
  }

  .block {
    min-height: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .content {
    height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .media-left figure.image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button_micro_dark_outlined {
    border: solid 2px #262626;
  }

  .media + .media {
    border-top: none;
  }
  .media_border_bottom + .media_border_bottom {
    border-top: 1px solid #e8e8e880;
  }

  .icon_delete {
    cursor: pointer;
    width: 22px;
  }
</style>
